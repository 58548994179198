<template>
  <CBox
    font-family="Roboto"
    color="#111"
    w="100%"
    :px="['0px', '120px']"
    :py="['0px', '30px']"
    :bg="['#F2F2F2', 'white']"
    :h="['calc(100vh - 126px)', '100%']"
    :border="['none', '1px solid #f2f2f2']"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['none', '16px']"
  >
    <Portal to="breadcrumb">
      <BreadcrumbPath
        px="90px"
        py="20px"
        :paths="[
          {
            label: 'Dashboard',
            href: { name: 'client.index' }
          },
          {
            label: 'Profil',
            href: { name: 'client.profile' }
          },
          {
            label: 'Riwayat Transaksi',
            isCurrent: true,
          },
        ]"
      />
    </Portal>
    <CHeading
      as="h1"
      font-weight="700"
      font-size="28px"
      :d="['none', 'block']"
      :mx="['16px', '0px']"
      font-family="Roboto"
    >
      Riwayat Transaksi
    </CHeading>
    <CBox
      v-dragscroll
      pb="8px"
      pt="16px"
      :px="['16px', '0px']"
      overflow="hidden"
      :position="['unset','relative']"
      :h="['unset', '82px']"
    >
      <CList
        d="flex"
        gap="4px"
        :position="['unset','absolute']"
      >
        <CListItem
          v-for="filter in LIST_FILTER"
          :key="filter.id"
          style="list-style: none;"
        >
          <CBox
            as="button"
            bg="white"
            border="2px solid #008C81"
            :px="['8px', '20px']"
            :py="['6px', '11px']"
            rounded="1000px"
            :font-size="['12px', '16px']"
            font-weight="400"
            white-space="nowrap"
            color="#008C81"
            :class="{'filter-active': selectedFilter === filter.id}"
            font-family="Roboto"
            @click="handleChangeFilter(filter.id)"
          >
            {{ filter.label }}
          </CBox>
        </CListItem>
      </CList>
    </CBox>

    <CBox
      v-if="fetchHistoryTransactionsQuery.data?.value?.length > 0"
      :px="['16px', '16px']"
      :mt="['0px','16px']"
      :mb="['16px', '0px']"
      :bg="['#F2F2F2', 'white']"
    >
      <CBox
        v-for="item in fetchHistoryTransactionsQuery.data.value"
        :key="item.invoice"
        v-chakra="{
          ':not(:last-child)': {
            marginBottom: '30px',
          }
        }"
        :p="['16px', '32px']"
        rounded="8px"
        box-shadow="0px 5px 15px rgb(0 0 0 / 20%)"
        bg="white"
        display="flex"
        gap="16px"
        cursor="pointer"
        @click="handleClickHistory(item.id)"
      >
        <CImage
          object-fit="cover"
          h="230.35px"
          w="180px"
          :src="item.image"
          alt="Image"
          rounded="6px"
          :display="['none', 'block']"
        />
        <CBox
          width="100%"
        >
          <CBox
            d="flex"
            align-items="center"
            justify-content="space-between"
            gap="8px"
          >
            <CBox
              display="flex"
              gap="8px"
              align-items="center"
            >
              <CImage
                object-fit="cover"
                h="50px"
                w="50px"
                :src="item.image"
                alt="Image"
                rounded="6px"
                :display="['block', 'none']"
              />
              <CText
                :font-size="['16px', '28px']"
                :font-weight="['600', '700']"
                d="flex"
                align-items="center"
                gap="8px"
                flex-wrap="wrap"
                font-family="Roboto"
              >
                {{ item.productName || '-' }}
                <CText
                  :font-size="['16px', '20px']"
                  :font-weight="['600', '700']"
                >
                  ({{ item.name || '-' }})
                </CText>
              </CText>
            </CBox>
            <LabelProgramStatus :status="checkStatusProgram(item.status, item.program)" />
          </CBox>
          <CDivider border-color="lightGray" />
          <CBox
            as="table"
            :font-size="['12px', '16px']"
            font-weight="400"
            style=" border-collapse: separate; border-spacing: 0px 8px;"
            font-family="Roboto"
          >
            <CBox as="tr">
              <CBox
                as="th"
                style="display: inline-block; margin-right: 16px;"
              >
                Invoice
              </CBox>
              <CBox
                as="td"
                color="#333333"
              >
                {{ item.invoice }}
              </CBox>
            </CBox>
            <CBox as="tr">
              <CBox
                as="th"
                style="display: inline-block; margin-right: 16px;"
              >
                Durasi
              </CBox>
              <CBox
                as="td"
                color="#333333"
              >
                {{ item.duration }} hari
              </CBox>
            </CBox>
            <CBox as="tr">
              <CBox
                as="th"
                text-align="start"
                style="display: inline-block; margin-right: 16px;"
              >
                Tanggal Transaksi
              </CBox>
              <CBox
                as="td"
                color="#333333"
              >
                {{ formatDateV2(item.date) }}
              </CBox>
            </CBox>
            <CBox as="tr">
              <CBox
                as="th"
                style="display: inline-block; margin-right: 16px;"
              >
                Status
              </CBox>
              <CBox as="td">
                <LabelTransactionStatus :status="item.status" />
              </CBox>
            </CBox>
            <CBox as="tr">
              <CBox
                as="th"
                style="display: inline-block; margin-right: 16px;"
              >
                Harga
              </CBox>
              <CBox
                as="td"
                color="#333333"
              >
                {{ formatCurrency(item.price) }}
              </CBox>
            </CBox>
          </CBox>
        </CBox>
      </CBox>
      <CBox
        :display="['block', 'none']"
        height="80px"
      >
&nbsp;
      </CBox>
    </CBox>
    <EmptyCard
      v-else
      label="Riwayat Transaksi tidak tersedia"
    />
  </CBox>
</template>

<script >
import { useQuery } from '@tanstack/vue-query';
import { ref } from '@vue/composition-api';
import { CImage, CDivider, CBox, CText, CHeading, CList, CListItem } from '@chakra-ui/vue';
import LabelProgramStatus from '@/views/profile/history-transaction/_widgets/label-program-status.vue';
import { useActions } from '@/stores';
import LabelTransactionStatus from '@/views/profile/history-transaction/_widgets/label-transaction-status.vue';
import { formatDateV2 } from '@/utils/format-date';
import { formatCurrency } from '@/utils/format-currency';
import EmptyCard from '@/views/profile/empty.vue';
import { dragscroll as vDragscroll } from 'vue-dragscroll';
import { useRouter } from 'vue2-helpers/vue-router';
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue';
import { Portal } from 'portal-vue';
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const router = useRouter();

  function checkStatusProgram(statusTransaction, statusProgram) {
    if (statusTransaction.toLowerCase() === 'pending') {
      return 'pending';
    }

    return statusProgram;
  }

  const selectedFilter = ref('ALL');
  const action = useActions('clients', ['fetchHistoryTransactions']);
  const fetchHistoryTransactionsQuery = useQuery({
    queryKey: ['useFetchHistoryTransactions', selectedFilter],
    queryFn: () => action.fetchHistoryTransactions({
      offset: 0,
      limit: 999999,
      filter_transaction: selectedFilter.value
    })
  });

  const handleClickHistory = transactionId => {
    router.push({
      name: 'client.profile.detailHistoryTransaction',
      params: {
        transactionId
      }
    });
  };

  const handleChangeFilter = filterId => {
    selectedFilter.value = filterId;
  };

  const LIST_FILTER = [{
    id: 'ALL',
    label: 'Seluruh Transaksi'
  }, {
    id: 'done',
    label: 'Transaksi Berhasil'
  }, {
    id: 'pending',
    label: 'Menunggu Pembayaran'
  }, {
    id: 'failed',
    label: 'Transaksi Gagal'
  }];
  return {
    formatDateV2,
    formatCurrency,
    checkStatusProgram,
    selectedFilter,
    fetchHistoryTransactionsQuery,
    handleClickHistory,
    handleChangeFilter,
    LIST_FILTER
  };
};

__sfc_main.components = Object.assign({
  CBox,
  Portal,
  BreadcrumbPath,
  CHeading,
  CList,
  CListItem,
  CImage,
  CText,
  LabelProgramStatus,
  CDivider,
  LabelTransactionStatus,
  EmptyCard
}, __sfc_main.components);
__sfc_main.directives = Object.assign({
  dragscroll: vDragscroll
}, __sfc_main.directives);
export default __sfc_main;
</script>

<style>
.filter-active {
  background: #008C81;
  color: white;
}
</style>